<template>
  <a-layout class="layout">
    <CommonHeader></CommonHeader>
    <a-layout-content class="bgc-grey about-us-page page-padding">
      <div class="about-us-left">
        <h1 class="title">
          {{ $t("ABOUT_US_TITLE") }}
        </h1>
        <p class="content about-us-text">
          {{ $t("ABOUT_US_PARAGRAPH_1") }}<br />
          <br />
          {{ $t("ABOUT_US_PARAGRAPH_2") }}<br />
          <br />
          {{ $t("ABOUT_US_PARAGRAPH_3") }}<br />
          <br />
          {{ $t("ABOUT_US_PARAGRAPH_4") }}<br />
          <br />
          {{ $t("ABOUT_US_PARAGRAPH_5") }}
        </p>
      </div>
      <div  class="about-us-right">
        <div class="img-top">
          <img src="@/assets/img/man.png" alt="" />
        </div>
        <p class="fw-400">{{ $t("PERSONAL_PROFILE") }}</p>
        <div class="img-end">
          <img src="@/assets/img/sdvosb.png" alt="" />
        </div>
      </div>
    </a-layout-content>
    <CommonFooter></CommonFooter>
  </a-layout>
</template>

<script>
import CommonHeader from "@/components/CommonHeader.vue";
import CommonFooter from "@/components/CommonFooter.vue";
export default {
  name: "AboutUsPage",
  components: {
    CommonHeader,
    CommonFooter,
  },
};
</script>

<style scoped lang="scss">
.about-us-page {
  text-align: left;
  color: var(--color1);
  display: flex;
  .about-us-left{
    margin-right: calc(100vw * (96 / 1440));
    h1 {
      margin-bottom: calc(100vw * (47 / 1440));
    }
    .about-us-text {
      white-space: pre-line;
      opacity: 0.6;
      width: calc(100vw * (612 / 1440));
    }
  }
  .about-us-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .img-top{
      img {
        width: auto;
        height: calc(100vw * (411 / 1440));
      }
    }
    p {
      white-space: nowrap;
      font-size: calc(100vw * (13 / 1440));
      line-height: calc(100vw * (26 / 1440));
      letter-spacing: 0.2px;
      margin-top: calc(100vw * (14 / 1440));
      margin-bottom: calc(100vw * (44 / 1440));
    }
    .img-end {
      text-align: center;
      img {
        background: lightgray 50% / cover no-repeat;
        mix-blend-mode: multiply;
        width: auto;
        height: calc(100vw * (157 / 1440));
      }
    }
  }
}
</style>