<template>
  <a-layout class="layout">
    <CommonHeader></CommonHeader>
    <a-layout-content class="bgc-grey faq-page page-padding">
      <h1 class="title">{{ $t("frequently_asked_questions")}}</h1>
      <div
        class="qa-content"
        v-for="(qaItem, qaIndex) in fqaArr"
        :key="qaIndex"
      >
        <p class="content fw-600">{{ qaItem.q }}</p>
        <p class="content">{{ qaItem.a }}</p>
        <a-divider v-if="qaIndex != fqaArr.length - 1" class="qa-divider" />
      </div>
    </a-layout-content>
    <CommonFooter></CommonFooter>
  </a-layout>
</template>

<script>
import CommonHeader from "@/components/CommonHeader.vue";
import CommonFooter from "@/components/CommonFooter.vue";
import { useI18n } from "vue-i18n";
export default {
  name: "FAQPage",
  components: {
    CommonHeader,
    CommonFooter,
  },
  setup() {
    const { t } = useI18n();
    const lang = 'en';
    const fqaArr = [
      {
        q: t("q1", lang),
        a: t("a1", lang)
      },
      {
        q: t("q2", lang),
        a: t("a2", lang)
      },
      {
        q: t("q3", lang),
        a: t("a3", lang)
      },
      {
        q: t("q4", lang),
        a: t("a4", lang)
      },
      {
        q: t("q5", lang),
        a: t("a5", lang)
      }
    ];
    return {
      fqaArr,
    };
  },
};
</script>

<style scoped lang="scss">
.faq-page {
  text-align: left;
  h1 {
    margin-bottom: calc(100vw * (72 / 1440));
  }
  .qa-content {
    opacity: 0.6;
    p:nth-child(1) {
      margin-bottom: calc(100vw * (2 / 1440));
    }
    p:nth-child(2) {
      margin-bottom: calc(100vw * (35 / 1440));
    }
    .qa-divider {
      background: #000;
      opacity: 1;
    }
  }
}
</style>