<template>
  <a-layout>
    <CommonHeader></CommonHeader>
    <a-layout-content  class="invest-page bgc-white">
      <div class="left-box">
        <h1 class="title">
          {{ $t("Invest_Title")}}
        </h1>
        <div class="content">
          <a-divider />
          {{ $t("INVEST_INTRODUCE") }}
        </div>
      </div>
      <div class="right-box">
        <a-form layout="vertical" :rules="rules" :model="formState" ref="formRef">
          <a-form-item name="name" :label="$t('Form_Full_Name')">
            <a-input v-model:value="formState.name" :placeholder="$t('Form_Full_Name_Placeholder')" />
          </a-form-item>
          <a-form-item name="email" :label="$t('Form_Email_Address')">
            <a-input v-model:value="formState.email" placeholder="Ex. Johndoe@example.com" />
          </a-form-item>
          <a-form-item name="phone" :label="$t('Form_Phone_Number')">
            <a-input v-model:value="formState.phone" :placeholder="$t('Form_Phone_Number_Placeholder')" />
          </a-form-item>
          <a-form-item name="description" :label="$t('Form_Description')">
            <a-textarea v-model:value="formState.description" :placeholder="$t('Form_Description_Placeholder')" :rows="12" />
          </a-form-item>
          <a-form-item>
            <a-button class="btn-1 fw-400" shape="round" @click="onSubmit" :loading="showLoading">{{$t('Form_Invest_Submit')}}</a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-layout-content>
    <CommonFooter></CommonFooter>
  </a-layout>
</template>

<script>
import CommonHeader from "@/components/CommonHeader.vue";
import CommonFooter from "@/components/CommonFooter.vue";
import { reactive, ref, toRaw } from 'vue';
import { post } from '@/api/http';
import { message } from 'ant-design-vue';
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
export default {
  name: "InvestPage",
  components: {
    CommonHeader,
    CommonFooter,
  },
  setup() {
    const { t } = useI18n();
    const lang = 'en';
    const formRef = ref();
    const router = useRouter();
    const showLoading = ref(false);
    const formState = reactive({
      name: '',
      email: '',
      phone: '',
      description: ''
    });
    const rules = {
      name: [
        {
          required: true,
          message: t("Form_Full_Name_Required_Verification", lang),
          trigger: 'blur',
        }
      ],
      email: [
        {
          required: true,
          message: t("Form_Email_Address_Required_Verification", lang),
          trigger: 'blur',
        },
        {
          pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
          message: t("Form_Email_Address_Format_Verification", lang),
          trigger: 'blur',
        },
      ],
      phone: [
        {
          required: true,
          message:  t("Form_Phone_Number_Required_Verification", lang),
          trigger: 'blur',
        },
        {
          pattern: /^[\d\s()-]+$/,
          message: t("Form_Phone_Number_Format_Verification", lang),
          trigger: 'blur',
        },
      ],
      description: [
        {
          required: true,
          message: t("Form_Description_Required_Verification", lang),
          trigger: 'blur',
        },
      ]
    };
    const onSubmit = async() => {
      try {
        showLoading.value = true;
        await formRef.value.validate();
        console.log('values', formState, toRaw(formState));
        const formValues = toRaw(formState);
        const response = await post('/system/mail/send/contact', {
          fullName: formValues.name,
          mailAddress: formValues.email,
          phoneNumber: formValues.phone,
          description: formValues.description
        });
        console.log('response data', response);
        if (response.code === 0) {
          formRef.value.resetFields();
          router.push({
            path: '/submitResultPage',
          })

        } else {
          message.error(t("EMAIL_SENT_FAILED", lang));
        }
      } catch (error) {
        console.error('--- error ----', error);
      }
      showLoading.value = false;
    };
    return {
      formRef,
      formState,
      rules,
      onSubmit,
      showLoading
    };
  },
};
</script>

<style scoped lang="scss">
.invest-page {
  padding: calc(100vw * (87 / 1440)) calc(100vw * (95/ 1440)) calc(100vw * (115 / 1440));
  text-align: left;
  color: var(--color1);
  display: flex;
  .left-box {
    width: calc(100vw * (545 / 1440));
    margin-right: calc(100vw * (80 / 1440));
    h1 {
      line-height: calc(100vw * (60 / 1440));
      margin-bottom: calc(100vw * (48 / 1440));
    }
    .content {
      .ant-divider {
        margin: 0;
        margin-bottom: calc(100vw * (50 / 1440));
        border-block-start: 1px solid rgba(173, 173, 173, 1);
      }
      width: calc(100vw * (496/ 1440));
    }
  }
  .right-box {
    width: calc(100vw * (600/ 1440));
    ::v-deep .ant-form-item-label {
      label{
        font-size: calc(100vw * (16 / 1440));
        font-weight: 600;
        line-height: calc(100vw * (24 / 1440));
        color: var(--color1);
      }
    }
    .ant-form-item {
      text-align: right;
      margin-bottom: calc(100vw * (30 / 1440));
      .ant-btn {
        width: calc(100vw * (125 / 1440));
        padding: calc(100vw * (4 / 1440)) calc(100vw * (16 / 1440));
      }
      ::v-deep .ant-form-item-label{
        padding-bottom: calc(100vw * (14 / 1440));
      }
      ::v-deep .ant-form-item-control-input {
        min-height: calc(100vw * (53 / 1440));
      }
    }
    ::v-deep .ant-input {
      border-radius: 5px;
      font-weight: 400;
      font-size: calc(100vw * (14 / 1440));
      line-height: calc(100vw * (21 / 1440));
      
    }
    ::v-deep input {
      height: calc(100vw * (53 / 1440));
    }
    ::v-deep .ant-form-item-explain-error {
      font-size: calc(100vw * (14 / 1440));
    }
  }
}
</style>
