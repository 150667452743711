<template>
  <a-layout-footer class="fw-400">
    <a-row class="footer-part1" justify="end">
      <a-col :span="8" class="footer-left">
        <div class="hand-cursor" @click="goToPage('/',{position: 'fromLogo'})">
          <img class="logo-img" src="@/assets/img/logo.png" alt="" />
        </div>
      </a-col>
      <a-col :span="16" class="footer-right">
        <div class="fw-400 fr-col1">
          <h3>{{ $t("main_categories") }}</h3>
          <p v-if="SWITCH.PRODUCTS">{{ $t("products") }}</p>
          <p v-if="SWITCH.ABOUT_US" class="hand-cursor" @click="goToPage('/aboutUsPage')">{{ $t("about_Us") }}</p>
          <p v-if="SWITCH.TESTIMONIALS" class="hand-cursor"  @click="goToPage('/',{},'#testimonials')">{{ $t("testimonials") }}</p>
          <p v-if="SWITCH.OUR_TEAM" class="hand-cursor" @click="goToPage('/ourTeamPage')">{{ $t("our_team") }}</p>
          <p v-if="SWITCH.INVEST" class="hand-cursor" @click="goToPage('/investPage')">{{ $t("invest") }}</p>
        </div>
        <div class="fw-300 fr-col2">
          <h3>{{ $t("contacts") }}</h3>
          <a href="mailto:info@sierausa.com">info@sierausa.com</a>
          <p>{{ $t("contact_addr") }}</p>
          <p>{{ $t("contact_tel") }}</p>
        </div>
        <div class="fr-col3">
          <div class="follow_us">
            <h3>{{ $t("follow_us") }}</h3>
            <img src="@/assets/img/line.png" alt="" />
          </div>
          <div class="camera">
            <img src="@/assets/img/camera.png" alt="" />
          </div>
          <div class="multi-language">
            <img src="@/assets/img/us.png" alt="" />
            <a-select
              class="country-select"
              v-model:value="value"
              :bordered="false"
              :options="items.map((item) => ({ value: item }))"
            >
            </a-select>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-divider class="divider" />
    <a-row class="footer-part2">
      <a-col :span="12" class="fw-400">
        <router-link to="/fqaPage">
          <span>{{ $t("faq") }}</span>
        </router-link>
        <router-link to="/privacyPolicyPage">
          <span> {{ $t("privacy_policy") }}</span>
        </router-link>
      </a-col>
      <a-col :span="12">
        <span>{{ $t("test") }}</span>
      </a-col>
    </a-row>
  </a-layout-footer>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "CommonFooter",
  setup() {
    const SWITCH = {
      PRODUCTS: false,
      ABOUT_US: true,
      TESTIMONIALS: false,
      OUR_TEAM: true,
      INVEST: true,
    }
    const items = ref(["USA"]);
    const value = ref("USA");
    const router = useRouter();
    const goToPage = (path,query={},hash = '') => {
      router.push({
        path: path,
        query: query,
        hash: hash
      })
    }
    return {
      items,
      value,
      goToPage,
      SWITCH
    };
  },
};
</script>

<style scoped>
.ant-layout-footer {
  border: 1px solid #212a3e;
  background: var(--color4);
  color: rgba(255, 255, 255, 0.5);
  font-size: calc(100vw * (17 / 1440));
  line-height: calc(100vw * (23 / 1440));
  padding: 0;
  letter-spacing: 0.2px;
}
.footer-part1 {
  padding-left: calc(100vw * (156 / 1440));
}
.footer-left {
  padding-top: calc(100vw * (76 / 1440));
  padding-bottom: calc(100vw * (100 / 1440));
  text-align: left;
}
.footer-left .logo-img {
  height: calc(100vw * (65 / 1440));
}
.footer-right {
  padding-top: calc(100vw * (44 / 1440));
  padding-left: calc(100vw * (135 / 1440));
  display: flex;
  flex-wrap: nowrap;
}
.footer-right > div {
  text-align: left;
}
.footer-right h3 {
  color: #fff !important;
  font-size: calc(100vw * (16 / 1440));
  line-height: calc(100vw * (16 / 1440));
  opacity: 1 !important;
}
.footer-right p {
  opacity: 0.8;
}
.fr-col1 {
  margin-right: calc(100vw * (104 / 1440));
  width: calc(100vw * (124 / 1440));
}
.fr-col1 h3 {
  margin-bottom: calc(100vw * (22 / 1440));
}
.fr-col1 p {
  color: rgba(255, 255, 255, 0.5);
  font-size: calc(100vw * (17 / 1440));
  line-height: calc(100vw * (23 / 1440));
  letter-spacing: 0.2px;
  margin-bottom: calc(100vw * (12 / 1440));
}
.fr-col2 {
  width: calc(100vw * (140 / 1440));
  margin-right: calc(100vw * (90 / 1440));
}
.fr-col2 h3 {
  margin-bottom: calc(100vw * (22 / 1440));
}
.fr-col2 p,
a {
  display: inline-block;
  color: #fff;
  font-size: calc(100vw * (16 / 1440));
  line-height: calc(100vw * (25 / 1440));
  letter-spacing: 0.2px;
  opacity: 0.8;
  margin-bottom: calc(100vw * (10 / 1440));
}
.fr-col3 {
  width: calc(100vw * (140 / 1440));
}
.fr-col3 h3 {
  margin-bottom: calc(100vw * (9 / 1440));
}

.follow_us {
  margin-bottom: calc(100vw * (18 / 1440));
}
.follow_us img {
  width: calc(100vw * (70 / 1440));
  display: block;
}
.camera {
  margin-bottom: calc(100vw * (65 / 1440));
}
.camera img {
  width: calc(100vw * (19 / 1440));
}
.country-select {
  width: calc(100vw * (90 / 1440)) !important;
}
.country-select /deep/ .ant-select-selection-item {
  font-size: calc(100vw * (16 / 1440));
  font-weight: 300;
  line-height: calc(100vw * (25 / 1440));
  padding: 0;
  color: #fff;
  opacity: 0.8;
  letter-spacing: 0.2px;
}
.country-select /deep/ .ant-select-selector {
  padding: 0;
  height: calc(100vw * (25 / 1440));
}
.country-select /deep/ .ant-select-arrow {
  color: #fff;
  font-size: calc(100vw * (12 / 1440));
  vertical-align: middle;
}
.multi-language {
  white-space: nowrap;
}
.multi-language img {
  width: calc(100vw * (20 / 1440));
  margin-right: calc(100vw * (8 / 1440));
  vertical-align: inherit;
}
.divider {
  opacity: 0.1;
  background: #fff;
  margin: 0;
}
.footer-part2 {
  padding-left: calc(100vw * (164 / 1440));
  padding-right: calc(100vw * (145 / 1440));
}
.footer-part2 .ant-col:nth-child(1) {
  text-align: left;
  padding-top: calc(100vw * (32 / 1440));
  padding-bottom: calc(100vw * (40 / 1440));
}
.footer-part2 .ant-col:nth-child(1) a {
  color: #fff;
  opacity: 0.8;
  font-size: calc(100vw * (17 / 1440));
  line-height: calc(100vw * (23 / 1440));
}
.footer-part2 .ant-col:nth-child(1) a {
  margin-bottom: 0;
}
.footer-part2 .ant-col:nth-child(1) a:nth-child(1) {
  margin-right: calc(100vw * (33.5 / 1440));
}
.footer-part2 .ant-col:nth-child(2) {
  color: rgba(255, 255, 255, 0.5);
  font-size: calc(100vw * (15 / 1440));
  font-weight: 400;
  line-height: calc(100vw * (23 / 1440));
  text-align: right;
  opacity: 0.8;
  padding-top: calc(100vw * (30 / 1440));
  padding-bottom: calc(100vw * (42 / 1440));
}
.hand-cursor {
  cursor: pointer;
}
</style>