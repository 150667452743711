<template>
  <a-layout class="layout">
    <a-layout-content>
      <CommonHeader @product-menu="goToSwiper"></CommonHeader>
      <a-row class="top-box" v-if="DISPLAT_OF_EACH_SECTION.TOP_BOX">
        <a-col class="top-box-left" :span="14">
          <h1 class="top-box-title fw-400">
            {{ $t("main_title") }}
          </h1>
          <div class="top-box-content fw-300">
            {{ $t("product_description") }}
          </div>
          <div class="schedule-btn">
            <a-button shape="round" class="btn-1 fw-400 button" @click="showOrderForm">
              <template #icon>
                {{ $t("btn_order_now") }}
                <RightCircleFilled class="btn-icon" />
              </template>
            </a-button>
          </div>
        </a-col>
        <a-col class="top-box-right" :span="10">
          <div class="product_1">
            <img src="@/assets/img/product-side.png" alt="" />
          </div>
        </a-col>
      </a-row>
      <section class="top-box-batch2" v-if="DISPLAT_OF_EACH_SECTION.TOP_BOX_BATCH2">
        <div>
          <img src="@/assets/img/logo-2.png" alt=""> 
        </div>
        <div> 
          <h1 class="fw-300">{{ $t('Home_Page_Top_Box_Title') }}</h1>
        </div>
      </section>
      <section class="box2-batch2">
        <a-row v-for="(item, index) in box2Bbatch2List" :key="index">
          <a-col span="12" :order="item.imgPosition == 'left'? 1:3">
            <img class="main-picture" :src="item.mainPicture" alt="">
          </a-col>
          <a-col class="content-box fw-300" span="12" :order="2">
            <img :src="item.icon" alt="">
            <p>{{ item.title }}</p>
            <p>{{ item.content }}</p>
          </a-col>
        </a-row>
      </section>
      <a-row class="box-2 bgc-dark fw-300" v-if="DISPLAT_OF_EACH_SECTION.BOX2">
        <a-col>
          <img src="@/assets/img/independent.png" alt="" />
          <div class="box-2-title">{{ $t("independent_title") }}</div>
          <div>
            {{ $t("independent_content") }}
          </div>
        </a-col>
        <a-col>
          <img src="@/assets/img/clean.png" alt="" />
          <div class="box-2-title">{{ $t("clean_title") }}</div>
          <div>
            {{ $t("clean_content") }}
          </div>
        </a-col>
        <a-col>
          <img src="@/assets/img/resilient.png" alt="" />
          <div class="box-2-title">{{ $t("resilient_title") }}</div>
          <div>
            {{ $t("resilient_content") }}
          </div>
        </a-col>
      </a-row>
      <a-row class="box-3" v-if="DISPLAT_OF_EACH_SECTION.BOX3">
        <div></div>
        <div></div>
      </a-row>
      <div class="slides-wrapper" v-if="DISPLAT_OF_EACH_SECTION.SLIDES_WRAPPER">
        <section
          class="panel"
          v-for="swiper in swiperList"
          :key="swiper.id"
          :id="swiper.id"
        >
          <div class="panel-content"> 
            <div v-if="swiper.uiType=='whole'" class="entire-img"> 
              <img
                :src="swiper.img"
                :alt="swiper.id"
                class="swiper-img"
                :style="{height: adaptScreen(1063),width: '100%'}"
              />
            </div>
            <div v-if="swiper.uiType=='right'" class="right-img" :style="{height: adaptScreen(1063),width: '100%'}">
              <div 
                class="left-box fw-300"
                :style="{
                  marginRight: adaptScreen(80),
                }"
              >
                <h2
                  :style="{
                    fontSize: adaptScreen(46),
                    lineHeight: adaptScreen(54),
                    marginBottom: adaptScreen(28),
                  }"
                >{{ swiper.productParameters.title }}</h2>
                <div class="gradient-line"></div>
                <p
                  :style="{
                    fontSize: adaptScreen(17),
                    lineHeight: adaptScreen(30),
                    width: adaptScreen(405),
                    marginTop: adaptScreen(36),
                  }"
                >{{ swiper.productParameters.content }}</p>
              </div>
              <div class="right-box">
                <img :src="swiper.img" alt="" :style="{width: adaptScreen(swiper.imgWidth)}">
              </div>
            </div>
            <div v-if="swiper.uiType=='left'" class="left-img" :style="{height: adaptScreen(1063),width: '100%'}">
              <div class="left-box" :style="{marginRight: adaptScreen(210)}">
                <img :src="swiper.img" alt="" :style="{width: adaptScreen(swiper.imgWidth)}">
              </div>
              <div class="right-box fw-300">
                <h2
                  :style="{
                    fontSize: adaptScreen(46),
                    lineHeight: adaptScreen(54),
                    marginBottom: adaptScreen(12),
                  }"
                >
                  {{ swiper.productParameters.title }}
                  <div class="gradient-line" :style="{marginTop: adaptScreen(24)}"></div>
                </h2>
                <div class="content-list" v-for="(item, index) in swiper.productParameters.content" :key="index">
                  <p
                    class="fw-400"
                    :style="{
                      fontSize: adaptScreen(24),
                      lineHeight: adaptScreen(30),
                      marginBottom: adaptScreen(16),
                      marginTop: adaptScreen(24),
                    }"
                  >{{ item.subTitle }}</p>
                  <p
                    :style="{
                      fontSize: adaptScreen(16),
                      lineHeight: adaptScreen(26),
                      width: adaptScreen(420),
                    }"
                  >{{ item.subContent }}</p>
                  <div 
                    class="auxiliary-line"
                    :style="{
                      top: adaptScreen(10),
                      left: adaptScreen(-290),
                    }"
                  >
                    <div class="filled-dots" :style="{width: adaptScreen(16),height: adaptScreen(16)}"></div>
                    <div class="gradient-line" :style="{width: adaptScreen(299)}"></div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="swiper.uiType=='center'" class="center-img" :style="{height: adaptScreen(1063),width: '100%'}">
              <div class="product-model-btn" :style="{marginBottom: adaptScreen(48), marginTop: adaptScreen(75)}">
                <div v-for="(mode,modeIndex) in swiper.productModelList"  :key="modeIndex">
                  <a-button
                    :class="{'ant-btn-opacity': swiper.id == mode.id}"
                    :style="{
                      width: adaptScreen(190),
                      height: adaptScreen(48),
                      fontSize: adaptScreen(32),
                      lineHeight: adaptScreen(30),
                    }" 
                    @click="goToProductSwiper(mode.id)"
                    type="text" :disabled="swiper.id == mode.id">{{ mode.name }}
                  </a-button>
                  <img v-if="modeIndex != swiper.productModelList.length-1" :style="{width:adaptScreen(55)}" src="@/assets/img/logo-s.png" alt="">
                </div>
              </div>
              <div class="main-content fw-300"
                :style="{
                  fontSize: adaptScreen(16),
                  lineHeight: adaptScreen(26),
                }"
              >
                <div 
                  class="swiper-text swiper-text-left" 
                  :style="{
                    width: adaptScreen(210),
                    paddingTop: adaptScreen(120),
                  }"
                >
                  <div 
                    v-for="parameter in swiper.productParameters.slice(0, swiper.productParameters.length / 2)"
                    :key="parameter.index"
                  >
                    <p 
                      class="fw-400"
                      :style="{
                        fontSize: adaptScreen(20),
                        lineHeight: adaptScreen(30),
                        marginBottom: adaptScreen(24),
                      }"
                    >{{ parameter.parameterName }}</p>
                    <p v-for="(value, index) in parameter.parameterValue" :key="index">
                      {{ value }}
                    </p>
                    <div
                      :style="{
                        marginTop: adaptScreen(24),
                        marginBottom: adaptScreen(24),
                        width: adaptScreen(64),
                      }"
                      v-if="parameter.index != swiper.productParameters.length / 2" class="gradient-line"></div>
                  </div>
                </div>
                <div 
                  class="swiper-product-img"
                  :style="{
                    margin: '0 ' + adaptScreen(115),
                    marginBottom: adaptScreen(50),
                  }"
                >
                  <img
                    :style="{
                      width: adaptScreen(swiper.imgWidth),
                    }"
                    :src="swiper.img"
                    alt=""
                  >
                </div>
                <div 
                  class="swiper-text swiper-text-right" 
                  :style="{
                    width: adaptScreen(210),
                    paddingTop: adaptScreen(120),
                  }"
                >
                  <div v-for="parameter in swiper.productParameters.slice(Math.floor(swiper.productParameters.length / 2))"
                    :key="parameter.index"
                  >
                    <p
                      class="fw-400"
                      :style="{
                        fontSize: adaptScreen(20),
                        lineHeight: adaptScreen(30),
                        marginBottom: adaptScreen(24),
                      }"
                    >{{ parameter.parameterName }}</p>
                    <p v-for="(value, index) in parameter.parameterValue" :key="index">
                      {{ value }}
                    </p>
                    <div
                      :style="{
                        marginTop: adaptScreen(24),
                        marginBottom: adaptScreen(24),
                        width: adaptScreen(64),
                      }"
                      v-if="parameter.index != swiper.productParameters.length" class="gradient-line"></div>
                  </div>
                </div>
              </div>
              <div class="order-now-btn">
                <a-button
                  v-if="swiper.showOrderNowBtn"
                  shape="round"
                  class="carousel-btn btn-1 fw-400"
                  :style="{
                    fontSize: adaptScreen(20),
                    height: adaptScreen(60),
                    lineHeight: adaptScreen(25),
                  }"
                  @click="showOrderForm"
                >
                  <template #icon>
                    {{ $t("Preorder_Btn") }}
                    <RightCircleFilled
                      :style="{
                        fontSize: adaptScreen(20),
                        marginLeft: adaptScreen(15),
                      }"
                      class="btn-icon"
                    />
                  </template>
                </a-button>
              </div>
            </div>
          </div>
        </section>
      </div>
      <a-row class="box-4 bgc-grey" v-if="DISPLAT_OF_EACH_SECTION.BOX4">
        <p class="title box4-title">
          {{ $t("APPLICATION_TITLE") }}
        </p>
        <p class="content box4-content1">
          {{ $t("APPLICATION_INTRODUCTION") }}
        </p>
        <a-col :span="24" class="box4-content2">
          <div class="product-img-container"><img src="@/assets/img/product-app3.png" alt="" /></div>
          <a-steps :current="0" direction="vertical">
            <a-step
              v-for="(stepItem, index) in stepArr"
              :title="stepItem.title"
              :description="stepItem.description"
              :key="index"
            />
          </a-steps>
        </a-col>
      </a-row>
      <a-row class="box-5 bgc-dark" id="testimonials" style="height: auto;" v-if="DISPLAT_OF_EACH_SECTION.BOX5">
        <a-col :span="24">
          <h1 class="title fw-300">{{ $t("testimonials") }}</h1>
          <div class="carousel-people">
            <a-carousel :slidesToShow="5" :draggable="true" :dots="false">
              <div
                class="carousel-people-item"
                v-for="(item, index) in testimonialsArr"
                :key="index"
                :class="index === currentIndex ? '' : 'mask'"
                @click="handleChange(index)"
              >
                <img :src="item.url" alt="" />
              </div>
            </a-carousel>
          </div>
          <div class="content box5-content1">
            {{ testimonialsArr[currentIndex].content }}
          </div>
          <div class="box5-content2">
            <p class="fw-400">{{ testimonialsArr[currentIndex].name }}</p>
            <p class="fw-350">{{ testimonialsArr[currentIndex].theme }}</p>
          </div>
        </a-col>
      </a-row>
      <a-row class="box-6 bgc-white" v-if="DISPLAT_OF_EACH_SECTION.BOX6">
        <a-col :span="14" class="box-6-left">
          <h1 class="title box6-title">
            {{ $t("company_profile_title") }}
          </h1>
          <p class="content box6-content">
            {{ $t("company_profile_content") }}
          </p>
          <div class="learn-more">
            <a-button
              type="text"
              class="learn-more-btn fw-400 button"
              @click="goToAboutUs"
            >
              <template #icon>
                {{ $t("LEARN_MORE") }}
                <ArrowRightOutlined />
              </template>
            </a-button>
          </div>
          <div>
            <img class="sdvo" src="@/assets/img/sdvosb.png" alt="" />
          </div>
        </a-col>
        <a-col :span="10" class="box-6-right">
          <div>
            <img src="@/assets/img/man.png" alt="" />
          </div>
          <p class="fw-400">
            {{ $t("PERSONAL_PROFILE") }}
          </p>
        </a-col>
      </a-row>
      <CommonFooter></CommonFooter>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { RightCircleFilled, ArrowRightOutlined } from "@ant-design/icons-vue";
import CommonHeader from "@/components/CommonHeader.vue";
import CommonFooter from "@/components/CommonFooter.vue";
import { ref, nextTick, onMounted, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
export default {
  name: "HomePage",
  setup() {
    const DISPLAT_OF_EACH_SECTION = {
      TOP_BOX: false,
      TOP_BOX_BATCH2: true,
      BOX2: false,
      BOX3: false,
      SLIDES_WRAPPER: true,
      BOX4: true,
      BOX5: false,
      BOX6: true,
    }
    const currentIndex = ref(2);
    const { t } = useI18n();
    const lang = "en";
    const router = useRouter();
    const viewportWidth = ref(window.innerWidth)
    const viewportHeight = ref(window.innerHeight)
    const stepArr = [
      {
        title: t("STEP_TITLE1", lang),
        description: t("STEP_CONTENT1", lang),
      },
      {
        title: t("STEP_TITLE2", lang),
        description: t("STEP_CONTENT2", lang),
      },
      {
        title: t("STEP_TITLE3", lang),
        description: t("STEP_CONTENT3", lang),
      },
    ];
    const testimonialsArr = [
      {
        url: require("@/assets/img/people1.png"),
        content: '“하지만 모두들 네가 발언하는 것에 동의한다.너는 자외선차를 살 필요가 없다.얼마나 많은 사람이 벌을 받으면 산이 태어나고 우리는 우스워질 것이다.”',
        name: '박호현',
        theme: '내셔널 세미컨덕터',
      },
      {
        url: require("@/assets/img/people2.png"),
        content: '"But it is agreed that you will speak. You don\'t have to buy an ultraviolet car. As many as punished people, the mountains will be born, we will be ridiculous."',
        name: 'David Smith',
        theme: 'Thermo Fisher',
      },
      {
        url: require("@/assets/img/people3.png"),
        content: "“Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Sum sociis penatibus dis parturient montes, nascetur ridiculus mus. ”",
        name: t("testimonials_person1", lang),
        theme: t("testimonials_theme1", lang),
      },
      {
        url: require("@/assets/img/people4.png"),
        content: '"Pero todos están de acuerdo contigo en hablar. No tienes que comprar un coche ultravioleta. Como pareja de castigo, las montañas nacerán y nos volveremos absurdos."',
        name: 'Carlos',
        theme: 'D&B Hoovers',
      },
      {
        url: require("@/assets/img/people5.png"),
        content: '"Mais tout le monde est d\'accord pour que vous parliez. Vous n\'avez pas besoin d\'acheter une voiture UV. En tant que compagnons de punition, de grandes montagnes naîtront et nous deviendrons absurdes."',
        name: 'Jacques',
        theme: 'Batterie au lithium aéronautique',
      },
    ];
    const handleChange = (index) => {
      currentIndex.value = index;
    };
    const swiperNumber = ref(5);
    const swiperList = [
      {
        id: "swiper1",
        uiType: "right",
        img: require("@/assets/img/product-right.png"),
        showOrderNowBtn: false,
        productModelList: [],
        productParameters: {
          title:'Streamlined for Simplicity',
          content:'SIERA ESS’s unique stackable design not only offers cost-effective scalability to meet growing energy needs but also ensures that it integrates effortlessly with diverse solar energy systems.'
        },
        imgWidth: 480
      },
      {
        id: "swiper2",
        uiType: "right",
        img: require("@/assets/img/product48-front.png"),
        showOrderNowBtn: false,
        productModelList: [],
        productParameters: {
          title:'Engineered for Efficiency',
          content: "We've meticulously crafted this product with compactness and versatility at its core, making it suitable for a range of environments. Be it in a garage or a discreet location on the exterior of a home, SIERA ESS fits in unobtrusively."
        },
        imgWidth: 425
      },
      {
        id: "swiper3",
        uiType: "left",
        img: require("@/assets/img/product-left.png"),
        showOrderNowBtn: false,
        productModelList: [],
        productParameters: {
          title:'Key Features',
          content: [
            {
              subTitle:'Intelligent Battery Management',
              subContent: 'Revolutionizing energy control, SIERA ESS presents Intelligent Battery Management—an advanced feature empowering users to effortlessly oversee battery performance and output around the clock through our cutting-edge Internet of Things infrastructure.'
            },
            {
              subTitle:'Certified Assurance',
              subContent:" Elevating standards with UL certification, SIERA ESS guarantees a seamless fusion of user comfort and system longevity. Our state-of-the-art passive monitoring and protection system acts as a vigilant guardian, averting potential catastrophic events and ensuring unmatched reliability."
            },
            {
              subTitle:'Unparalleled Versatility',
              subContent:"Designed with precision, the system's compactness offers unparalleled versatility. Whether wall-mounted or set directly on the ground, SIERA ESS's NEMA 3R rating and intelligent controls grant the flexibility to position the unit both indoors and outdoors, transcending weather constraints. Welcome to a new era of intelligent, certified, and weather-resistant energy solutions with SIERA ESS."
            }
          ]
        },
        imgWidth: 450
      },
      {
        id: "SIERA-48",
        uiType: "center",
        img: require("@/assets/img/product48-front.png"),
        showOrderNowBtn: true,
        productModelList: [
          {
            id:"SIERA-48",
            name:"ESS 48"
          },{
            id:"SIERA-600",
            name:"ESS 600"
          }
        ],
        productParameters: [
          {
            index:1,
            parameterName:'Total Energy',
            parameterValue:['13.2 kWh']
          },
          {
            index:2,
            parameterName:'Usable Energy',
            parameterValue:['12.6 kWh']
          },
          {
            index:3,
            parameterName:'Specification',
            parameterValue:['48 V (Nominal)','40 - 57.6V (Range)', '230 A']
          },
          {
            index:4,
            parameterName:'Scalable',
            parameterValue:['Up to 5 units (in parallel)']
          },
          {
            index:5,
            parameterName:'Size and Weight',
            parameterValue:['L x W x D',"40.75 in x 24.25 in x 7.36 in 264.5 lbs"]
          },
          {
            index:6,
            parameterName:'Installation',
            parameterValue:['Floor or wall mounted','Indoor or outdoor','-4F to 122 F']
          },
          {
            index:7,
            parameterName:'Warranty',
            parameterValue:['10 years']
          },
          {
            index:8,
            parameterName:'Inverter Compatibility',
            parameterValue:['Sol-Ark Inverter']
          }
        ],
        imgWidth: 425
      },
      {
        id: "SIERA-600",
        uiType: "center",
        img: require("@/assets/img/product600-front.png"),
        showOrderNowBtn: true,
        productModelList: [{
            id:"SIERA-48",
            name:"ESS 48"
          },{
            id:"SIERA-600",
            name:"ESS 600"
          }
        ],
        productParameters: [
          {
            index:1,
            parameterName:'Total Energy',
            parameterValue:['158.4 kWh']
          },
          {
            index:2,
            parameterName:'Usable Energy',
            parameterValue:['151.2 kWh']
          },
          {
            index:3,
            parameterName:'Specification',
            parameterValue:['600 V (Nominal)','480 - 691.2V (Range)', '230 A']
          },
          {
            index:4,
            parameterName:'Scalable',
            parameterValue:['Up to 12 units (in series)']
          },
          {
            index:5,
            parameterName:'Size and Weight',
            parameterValue:['Custom Design, Contact us for more information',]
          },
          {
            index:6,
            parameterName:'Installation',
            parameterValue:['Modular Unit','Indoor or Outdoor','-4F to 122 F']
          },
          {
            index:7,
            parameterName:'Warranty',
            parameterValue:['10 years']
          },
          {
            index:8,
            parameterName:'Inverter Compatibility',
            parameterValue:['Sol-Ark Inverter']
          }
        ],
        imgWidth: 425
      },
    ];
    const goToAboutUs = () => {
      router.push({ path: "/aboutUsPage" });
    };
    const setHeight = (h) => {
      let oldBoxHeight = viewportWidth.value * (1063 / 1440);
      let oldDivHeight = viewportWidth.value * (h / 1440);
      return (oldDivHeight / oldBoxHeight) * viewportHeight.value + "px";
    };
    const adaptScreen = (h) => {
      return viewportWidth.value * (1063 / 1440) >= viewportHeight.value ? setHeight(h) : viewportWidth.value * (h / 1440)+'px';
    }
    const goToSwiper = (data) => {
      let routerQuery = router.currentRoute.value.query;
      let type = data ? data : routerQuery.productType;
      console.log("type", type);
      if (
        routerQuery.position != "fromLogo" ||
        (routerQuery.position == "fromLogo" && type)
      ) {
        nextTick(() => {
          console.log("yes yes ");
          if(type) {
            let scrollToId = "#" + type.replace(/ /g, "-");
            gsap.to(window, { duration: 2, scrollTo: scrollToId });
          }
        });
      }
    };

    const goToProductSwiper = (id) => {
      if(id == "SIERA-48") {
        let element = document.querySelector("#SIERA-600");
        gsap.to(window, { 
          duration: 0.2, 
          scrollTo: { 
            y: element.offsetTop - viewportHeight.value,
            autoKill: false 
          } 
        });
      }else if(id == 'SIERA-600') {
        gsap.to(window, { duration: 0.2, scrollTo: "#" + id });
      }
    }
    
    const updateViewportSize = ()=> {
      viewportWidth.value = window.innerWidth;
      viewportHeight.value = window.innerHeight;
    }
    const box2Bbatch2List = [
      {
        mainPicture: require("@/assets/img/batch2-img1.png"),
        icon: require("@/assets/img/independent.png"),
        title: t("independent_title"),
        content: t("independent_content"),
        imgPosition: "left"
      },
      {
        mainPicture: require("@/assets/img/batch2-img2.png"),
        icon: require("@/assets/img/clean.png"),
        title: t("clean_title"),
        content: t("clean_content"),
        imgPosition: "right"
      },
      {
        mainPicture: require("@/assets/img/batch2-img3.png"),
        icon: require("@/assets/img/resilient.png"),
        title: t("resilient_title"),
        content: t("resilient_content"),
        imgPosition: "left"
      }
    ];
    const showOrderForm = () => {
      window.open('https://forms.office.com/r/QyHrk8cy4m', '_blank');
    }

    onMounted(() => {
      window.addEventListener("resize", updateViewportSize);

      gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
      var panels = gsap.utils.toArray(".panel");
      panels.pop();
      panels.forEach((panel) => {
        // Get the element holding the content inside the panel
        let innerpanel = panel.querySelector(".panel-content");
        // Get the Height of the content inside the panel
        let panelHeight = innerpanel.offsetHeight;
        // Get the window height
        let windowHeight = viewportHeight;
        let difference = panelHeight - windowHeight;
        // ratio (between 0 and 1) representing the portion of the overall animation that's for the fake-scrolling. We know that the scale & fade should happen over the course of 1 windowHeight, so we can figure out the ratio based on how far we must fake-scroll
        let fakeScrollRatio =
          difference > 0 ? difference / (difference + windowHeight) : 0;
        console.log("fakeScrollRatio", fakeScrollRatio);
        // if we need to fake scroll (because the panel is taller than the window), add the appropriate amount of margin to the bottom so that the next element comes in at the proper time.
        if (fakeScrollRatio) {
          panel.style.marginBottom = panelHeight * fakeScrollRatio + "px";
        }
        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: panel,
            start: "bottom bottom",
            end: () =>
              fakeScrollRatio ? `+=${innerpanel.offsetHeight}` : "bottom top",
            pinSpacing: false,
            pin: true,
            scrub: true,
          },
        });
        // fake scroll. We use 1 because that's what the rest of the timeline consists of (0.9 scale + 0.1 fade)
        if (fakeScrollRatio) {
          tl.to(innerpanel, {
            y: -difference,
            duration: 1 / (1 - fakeScrollRatio) - 1,
            ease: "none",
          });
        }
        tl.fromTo(
          panel,
          { scale: 1, opacity: 1 },
          { scale: 0.5, opacity: 0.5, duration: 0.9 }
        ).to(panel, { opacity: 0, duration: 0.1 });
      });
    });
    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateViewportSize);
    });
    return {
      currentIndex,
      handleChange,
      stepArr,
      testimonialsArr,
      goToAboutUs,
      swiperNumber,
      swiperList,
      setHeight,
      goToSwiper,
      goToProductSwiper,
      adaptScreen,
      viewportWidth,
      viewportHeight,
      DISPLAT_OF_EACH_SECTION,
      box2Bbatch2List,
      showOrderForm
    };
  },
  beforeRouteEnter(to, from, next) {
    if (to.name == "Home" && from.name != undefined && !to.hash) {
      next((vm) => {
        vm.goToSwiper();
      });
    } else {
      next();
    }
  },
  components: {
    RightCircleFilled,
    ArrowRightOutlined,
    CommonHeader,
    CommonFooter,
  },
};
</script>

<style scoped lang="scss">
.animate__fadeIn {
  --animate-duration: 4s;
}
.section-footer ::v-deep .fp-watermark {
  display: none;
}
.section-swiper div {
  width: 100%;
  text-align: center;
  background-color: rgb(7, 7, 7);
}
.section-swiper img {
  display: inline-block;
  width: auto;
  height: inherit;
}
.top-box {
  width: 100%;
  height: calc(100vw * (800 / 1440));
  background-image: url("@/assets/img/bgi1.jpg");
  background-size: cover;
  background-position: center;
}
.top-box-left {
  height: inherit;
  padding: 10.5% 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.top-box-left > * {
  width: 71.5%;
  margin: 0 auto;
}
.top-box-title {
  color: #fff;
  font-size: calc(100vw * (56 / 1440));
  line-height: calc(100vw * (65 / 1440));
  letter-spacing: -0.4px;
}
.top-box-content {
  color: #fff;
  font-size: calc(100vw * (18 / 1440));
  line-height: calc(100vw * (35 / 1440));
  letter-spacing: 0.2px;
  text-align: left;
  opacity: 0.6;
  margin: 8% auto 15%;
}
.schedule-btn {
  text-align: left;
}
.schedule-btn .ant-btn {
  padding: 0 calc(100vw * (22 / 1440));
}
.top-box-right {
  height: auto;
  position: relative;
}
.product_1 {
  position: absolute;
  right: calc(100vw * (20 / 1440));
  bottom: 0;
}

.product_1 img {
  width: 100%;
  height: calc(100vw * (620 / 1440));
}

.box-2 {
  color: #fff;
  letter-spacing: 0.2px;
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
  align-items: center;
  padding: calc(100vw * (81 / 1440)) calc(100vw * (117 / 1440));
}
.box-2 .ant-col {
  width: calc(100vw * (350 / 1440));
  height: auto;
}

.box-2 .ant-col:nth-child(2) {
  width: calc(100vw * (365 / 1440));
  margin-left: calc(100vw * (77 / 1440));
  margin-right: calc(100vw * (77 / 1440));
}
.box-2 img {
  height: calc(100vw * (44 / 1440));
  margin: 0 auto;
}
.box-2-title {
  margin-top: calc(100vw * (26 / 1440));
  margin-bottom: calc(100vw * (22 / 1440));
  font-size: calc(100vw * (20 / 1440));
  line-height: calc(100vw * (26 / 1440));
}
.box-2 .ant-col :last-child {
  font-size: calc(100vw * (16 / 1440));
  line-height: calc(100vw * (25 / 1440));
  opacity: 0.8;
  text-align: left;
}

.box-3 {
  height: calc(100vw * (720 / 1440));
}
.box-3 div {
  width: 50%;
  height: 100%;
  overflow: hidden;
}
.box-3 div:nth-child(1) {
  background: url("@/assets/img/box3-left.jpg") center 40% / cover no-repeat;
}
.box-3 div:nth-child(2) {
  background: url("@/assets/img/box3-right.jpg") right bottom / 135% no-repeat;
}

.carousel img {
  width: 100%;
  height: calc(100vw * (1063 / 1440));
  max-width: 100%;
}

.haveBtn {
  position: relative;
}
.box-4 {
  padding-top: calc(100vw * (63 / 1440));
}
.box4-title {
  width: calc(100vw * (759 / 1440));
  margin: 0 auto;
  margin-bottom: calc(100vw * (30 / 1440));
}
.box4-content1 {
  width: calc(100vw * (819 / 1440));
  margin: 0 auto;
  text-align: left;
  margin-bottom: calc(100vw * (40 / 1440));
}
.box4-content2 {
  display: flex;
  .product-img-container {
    height: calc(100vw * (684 / 1440));
    width: calc(100vw * (495 / 1440));
    position: relative;
    overflow: hidden;
  }
}
.box4-content2 > div:nth-child(1) {
  align-self: self-end;
  padding-left: calc(100vw * (265 / 1440));
  text-align: left;
  flex: 7;
}
.box4-content2 > div:nth-child(2) {
  padding-top: calc(100vw * (45 / 1440));
  flex: 9;
  padding-left: calc(100vw * (26 / 1440));
}
.box4-content2 > div {
  flex: 1;
}
.box4-content2 img {
  width: auto;
  height: calc(100vw * (730 / 1440));
  position: absolute;
  bottom: calc(100vw * (-46 / 1440));
}
.box4-content2 ::v-deep .ant-steps .ant-steps-item-process .ant-steps-item-title,
.box4-content2 ::v-deep .ant-steps .ant-steps-item-wait .ant-steps-item-title {
  color: var(--color1);
  font-size: calc(100vw * (20 / 1440));
  font-weight: 400;
  line-height: calc(100vw * (26 / 1440));
  letter-spacing: 0.2px;
  margin-top: calc(100vw * (2 / 1440));
  margin-bottom: calc(100vw * (10 / 1440));
}
.box4-content2
  ::v-deep
  .ant-steps
  .ant-steps-item-process
  .ant-steps-item-description,
.box4-content2
  ::v-deep
  .ant-steps
  .ant-steps-item-wait
  .ant-steps-item-description {
  color: var(--color1);
  font-size: calc(100vw * (14 / 1440));
  font-weight: 350;
  line-height: calc(100vw * (20 / 1440));
  letter-spacing: 0.2px;
  opacity: 0.8;
  width: calc(100vw * (360 / 1440));
}
.box4-content2 ::v-deep .ant-steps-item-icon {
  background-color: rgba(255, 255, 255, 0.2);
  border-color: var(--color6);
}
.box4-content2 ::v-deep .ant-steps .ant-steps-icon,
.box4-content2 ::v-deep .ant-steps .ant-steps-item-wait .ant-steps-icon {
  color: var(--color1);
}
.box-5 {
  padding-top: calc(100vw * (96 / 1440));
  padding-bottom: calc(100vw * (125 / 1440));
}
.box-5 .ant-col {
  margin: 0 auto;
}
.box-5 h1 {
  color: #fff;
  font-size: calc(100vw * (46 / 1440));
  line-height: calc(100vw * (54 / 1440));
  margin-bottom: calc(100vw * (87 / 1440));
}
.box5-content1 {
  color: var(--color3);
  opacity: 0.6;
  width: calc(100vw * (685 / 1440));
  height: auto;
  min-height: calc(100vw * (91 / 1440));
  margin: 0 auto;
  margin-top: calc(100vw * (61 / 1440));
  margin-bottom: calc(100vw * (49 / 1440));
  
}
.box-5-content2 {
  letter-spacing: 0.2px;
}
.box5-content2 :first-child {
  color: #fff;
  font-size: calc(100vw * (20 / 1440));
  line-height: calc(100vw * (26 / 1440));
  margin-bottom: calc(100vw * (4 / 1440));
}
.box5-content2 :last-child {
  color: var(--color3);
  /* font-family: Avenir; */
  font-size: calc(100vw * (14 / 1440));
  line-height: calc(100vw * (20 / 1440));
  opacity: 0.8;
  margin-bottom: 0;
}
.carousel-people {
  width: calc(100vw * (750 / 1440));
  margin: 0 auto;
}
.carousel-people-item {
  width: calc(100vw * (100 / 1440)) !important;
}
.carousel-people img {
  height: calc(100vw * (93 / 1440));
  width: auto;
  border-radius: 50%;
  position: relative;
}
.mask {
  opacity: 0.3;
}
.box-6-left {
  text-align: left;
  padding-top: calc(100vw * (104 / 1440));
  padding-left: calc(100vw * (150 / 1440));
}
.box6-title {
  width: calc(100vw * (624 / 1440));
  margin-top: calc(100vw * (36 / 1440));
}
.box6-content {
  width: calc(100vw * (612 / 1440));
  opacity: 0.6;
  margin-top: calc(100vw * (9 / 1440));
}
.learn-more {
  width: calc(100vw * (612 / 1440));
  text-align: right;
}
.learn-more-btn {
  height: calc(100vw * (32 / 1440));
  color: rgba(221, 90, 54, 0.86);
  text-align: center;
  font-size: calc(100vw * (16 / 1440));
  font-style: normal;
  line-height: calc(100vw * (32 / 1440));
  letter-spacing: 0.2px;
  width: calc(100vw * (120 / 1440));
}
.learn-more-btn .anticon {
  font-size: calc(100vw * (7 / 1440));
  vertical-align: middle;
  margin-left: calc(100vw * (8 / 1440));
}
.box-6 .box-6-left .sdvo {
  height: calc(100vw * (114 / 1440));
  background: lightgray 50% / cover no-repeat;
  mix-blend-mode: multiply;
}
.box-6-left > :last-child {
  padding-bottom: calc(100vw * (104 / 1440));
}
.box-6-right {
  padding-top: calc(100vw * (85 / 1440));
  padding-right: calc(100vw * (110 / 1440));
}
.box-6-right img {
  width: auto;
  height: calc(100vw * (412 / 1440));
}
.box-6-right p {
  color: var(--color1);
  font-size: calc(100vw * (13 / 1440));
  line-height: calc(100vw * (26 / 1440));
  margin-top: calc(100vw * (12 / 1440));
  letter-spacing: 0.2px;
}
.btn-icon {
  margin-left: calc(100vw * (15 / 1440));
  font-size: calc(100vw * (20 / 1440));
  vertical-align: sub;
  color: #03101f;
  opacity: 0.2;
}
.top-box-batch2{
  width: 100%;
  height: calc(100vw * (800 / 1440));
  background-color: var(--color4);
  background-image: url("@/assets/img/bgi2.png");
  background-size: cover;
  background-position: center;
  text-align: left;
  padding-left: calc(100vw * (128 / 1440));
  img{
    width: calc(100vw * (129 / 1440));
    height: calc(100vw * (129 / 1440));
    margin: calc(100vw * (225 / 1440)) 0 calc(100vw * (64 / 1440)) calc(100vw * (64 / 1440));
  }
  h1 {
    font-size: calc(100vw * (48 / 1440));
    line-height: calc(100vw * (67 / 1440));
    letter-spacing: 0.4px;
    color: var(--color7);
  }
}
.box2-batch2 {
  background-color: var(--color7);
  .main-picture {
    width: 100%;
    max-width: calc(100vw * (720 / 1440));
    height: calc(100vw * (698 / 1440));
  }
  .content-box {
    color: #000;
    letter-spacing: 0.2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      height: calc(100vw * (48 / 1440));
    }
    :nth-child(2){
      font-size: calc(100vw * (32 / 1440));
      line-height: calc(100vw * (26 / 1440));
      margin: calc(100vw * (48 / 1440)) 0;
    }
    :nth-child(3){
      font-size: calc(100vw * (20 / 1440));
      line-height: calc(100vw * (34 / 1440));
      width: calc(100vw * (524 / 1440));
      margin: 0 auto;
      opacity: 0.8;
      text-align: left;
    }
  }
}

html,
body {
  margin: 0;
  height: 100%;
  background: #000;
  color: #141414;
  -webkit-overflow-scrolling: touch;
  font-family: "Helvetica", sans-serif, Arial;
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
}
.slides-wrapper {
  background-color: rgb(7, 7, 7);
}
.panel-content {
  position: relative;
  align-items: center;
  .center-img {
    .product-model-btn {
      display: flex;
      justify-content: center;
      align-items: end;
      .ant-btn{
        color: white;
        opacity: 0.25;
      }
      .ant-btn[disabled] {
        cursor: default !important;
      }
      .ant-btn-opacity {
        opacity: 1;
      }
      img {
        vertical-align: text-bottom;
      }
    }
    .main-content {
      display: flex;
      .swiper-text {
        text-align: left;
        color: #fff;
        p {
          margin: 0;
          opacity: 0.7;
        }
      }
    }
  }
  .right-img {
    display: flex;
    justify-content: center;
    align-items: center;
    .left-box {
      width: auto;
      h2, p {
        color: #fff;
        text-align: left;
      }
      p{
        display: block;
        margin-bottom: 0;
        opacity: 0.7
      }
    }
  }
  .left-img {
    display: flex;
    justify-content: center;
    align-items: center;
    .right-box {
      text-align: left;
      color: #fff;
      h2 {
        display: inline-block;
        margin-bottom: 0;
      }
      .content-list {
        position: relative;
        p:nth-child(1) {
          color: var(--color6);
        }
        p:nth-child(2) {
          opacity: 0.7;
        }
        p {
          letter-spacing: 0.2px;
          margin-bottom: 0;
        }
      }
      .auxiliary-line {
        display: flex;
        justify-content: start;
        align-items: center;
        position: absolute;
        .filled-dots {
          background-color: var(--color6);
          border-radius: 100%;
          box-shadow: 0 0 24px 0 var(--color6);
        }
        .gradient-line {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.panel {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 1.5em;
  text-align: center;
  position: relative;
  box-sizing: border-box;
  background: rgb(7, 7, 7);
  overflow: hidden;
  // border: 10px solid yellow
}

/* Extra small devices (phones) */
@media (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones) */
@media (min-width: 600px) {
}

/* Medium devices (landscape tablets) */
@media (min-width: 768px) {
}

/* Large devices (laptops/desktops) */
@media (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops) */
@media (min-width: 1200px) {
  .ant-layout-header {
    background-color: var(--color4);
  }
}
</style>
