import { createApp } from 'vue';
import App from './App.vue';
import router from '../router' // 导入路由配置
import { createI18n } from 'vue-i18n';
import en from './assets/i18n/en';
import Antd from "ant-design-vue"
import 'ant-design-vue/dist/reset.css';
import '@/assets/fonts/OpenSans.css';
// 组合语言包对象
const messages = {
  en
}
 
// 创建实例对象
const i18n = createI18n({
  legacy: false,  // 设置为 false，启用 composition API 模式
  messages,
  locale: 'en'
})
 
// 创建 Vue 实例
const app = createApp(App);
 
// 注册对象
app.use(router)
app.use(i18n);
app.use(Antd)
// 挂载到 Dom 元素中
app.mount('#app');