<template>
    <a-layout>
      <CommonHeader></CommonHeader>
      <a-layout-content  class="submit-success bgc-white">
        <div class="success-card">
          <img class="ok-icon" src="@/assets/img/ok.png" alt="">
          <div class="surrounding-line">
            <div class="title">{{ $t("Thank_You") }}</div>
            <p class="content">{{ $t("Successfully_Message") }}</p>
          </div>
        </div>
      </a-layout-content>
      <CommonFooter></CommonFooter>
    </a-layout>
  </template>

<script>
import CommonHeader from "../components/CommonHeader.vue";
import CommonFooter from "../components/CommonFooter.vue";
export default {
    name: 'SubmitResultPage',
    components: {
        CommonHeader,
        CommonFooter,
    },
}
</script>

<style scoped lang="scss">
    .submit-success {
        padding-top: calc(100vw * (296 / 1440));
        padding-bottom: calc(100vw * (400 / 1440));
        .success-card {
            .ok-icon {
            width: calc(100vw * (48 / 1440));
            height: calc(100vw * (48 / 1440));
            margin-bottom: calc(100vw * (36 / 1440));
            }
            margin: 0 auto;
            width: calc(100vw * (1051/ 1440));
            .surrounding-line {
            border-radius: calc(100vw * (5 / 1440));
            border: 1px solid #A6A6A6;
            height: calc(100vw * (107 / 1440));
            position: relative;
            div {
                color: #212121;
                width: calc(100vw * (270/ 1440));
                background: white;
                position: absolute;
                top: calc(100vw * (-27 / 1440));
                left: 50%;
                transform: translateX(-50%);
            }
            p {
                margin-top: calc(100vw * (44 / 1440));
                opacity: 0.6;
            }
            }
        }
    }
</style>