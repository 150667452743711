<template>
  <a-layout class="layout">
    <CommonHeader></CommonHeader>
    <a-layout-content class="bgc-grey privacy-policy-page page-padding">
      <h1 class="title">{{ $t("privacy_policy") }}</h1>
      <p class="fw-400">
        Last updated January 24, 2024<br />
        This privacy policy for Siera USA (“Company”, “we”, “us”, or “our”),
        describes how and why we might collect, store, use, and/or share
        (“process”) your information when you use our services (“Services”),
        such as when you:<br />
        Visit our website at
        <a href="https://sieraess.com">https://sieraess.com</a> , or any website
        or applications of ours that links to this privacy policy<br />
        Engage with us in other related ways, including any sales, marketing, or
        events<br />
        Questions or concerns? Reading this privacy policy will help you
        understand your privacy rights and choices. If you do not agree with our
        policies and practices, please do not use our Services. If you still
        have any questions or concerns, please contact us at
        support@sieraess.com.<br />
        SUMMARY OF KEY POINTS<br />
        This summary provides key points from our privacy policy, but you can
        find out more details about any of these topics by clicking the link
        following each key point or by using our table of contents below to find
        the section you are looking for.<br />
        What personal information do we process? When you visit, use, or
        navigate our Services, we may process personal information depending on
        how you interact with Siera USA and the Services, the choices you make,
        and the products and features you use.<br />
        Do we process any sensitive personal information? We may process
        sensitive personal information when necessary with your consent or as
        otherwise permitted by applicable law.<br />
        Do we receive any information from third parties? We do not receive any
        information from third parties.<br />
        How do we process your information? We process your information to
        provide, improve, and administer our Services, communicate with you, for
        security and fraud prevention, and to comply with law. We may also
        process your information for other purposes with your consent. We
        process your information only when we have a valid legal reason to do
        so.<br />
        In what situations and with which parties do we share personal
        information? We may share information in specific situations and with
        specific third parties.<br />
        How do we keep your information safe? We have organizational and
        technical processes and procedures in place to protect your personal
        information. However, no electronic transmission over the internet or
        information storage technology can be guaranteed to be 100% secure, so
        we cannot promise or guarantee that hackers, cybercriminals, or other
        unauthorized third parties will not be able to defeat our security and
        improperly collect, access, steal, or modify your information.<br />
        What are your rights? Depending on where you are located geographically,
        the applicable privacy law may mean you have certain rights regarding
        your personal information.<br />
        TABLE OF CONTENTS<br />
        WHAT INFORMATION DO WE COLLECT?<br />
        HOW DO WE PROCESS YOUR INFORMATION?<br />
        WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?<br />
        WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?<br />
        DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?<br />
        HOW LONG DO WE KEEP YOUR INFORMATION?<br />
        HOW DO WE KEEP YOUR INFORMATION SAFE?<br />
        WHAT ARE YOU PRIVACY RIGHTS?<br />
        CONTROLS FOR DO-NOT-TRACK FEATURES<br />
        DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?<br />
        DO WE MAKE UPDATES TO THIS NOTICE?<br />
        HOW CAN YOU CONTACT US ABOUT THIS POLICY?<br />
        HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?<br />
        1. WHAT INFORMATION DO WE COLLECT?<br />
        Personal Information you disclose to us<br />
        In short: We collect personal information that you provide to us.<br />
        We collect personal information that you voluntarily provide to us when
        you register on the Services, express an interest in obtaining
        information about us or our products and Services, submitting an
        application, when you participate in activities on the Services, or
        otherwise when you contact us.<br />
        Personal Information Provided by You. The personal information that we
        collect depends on the context of your interaction with us and the
        Services, the choices you make, and the products and features you use.
        The personal information we collect may include the following:<br />
        Names<br />
        Phone Numbers<br />
        Email Addresses<br />
        Job Titles<br />
        Companies / Organizations<br />
        Contact or Authentication Data<br />
        Billing / Shipping Addresses<br />
        Debit / Credit Card Numbers, Bank Account Numbers<br />
        Contact Preferences<br />
        Sensitive Information. When necessary, with your consent or as otherwise
        permitted by applicable law, we process the following categories of
        sensitive information.<br />
        Financial Data<br />
        Biometric Data<br />
        Information Revealing Race or Ethnic Origin<br />
        Information Revealing Trade Union Membership<br />
        Student Data<br />
        Social Security Numbers or other Government Identifiers<br />
        Payment Data. We may collect data necessary to process your payment if
        you make payments for a product(s) or a service(s), such as your payment
        instrument number (i.e. credit card number) and security code associated
        with your payment instrument. All payment data is stored by Ceebig.<br />
        Information automatically collected<br />
        In short: Some information - such as your Internet Protocol (IP) address
        and/or browser and device characteristics - is collected automatically
        when you visit our Services.<br />
        We automatically collect certain information when you visit, use, or
        navigate the Services. This information does not reveal your specific
        identity (like your name or contact information) but may include device
        and usage information, such as your IP address, browser and device
        characteristics, operating system, language preferences, referring URLs,
        device name, country, location, information about how and when you use
        our Services, and other technical information. This information is
        primarily needed to maintain the security and operation of our Services,
        and for our internal analytics and reporting purposes.<br />
        Like many businesses, we also collect information through cookies and
        similar technologies.<br />
        The information we collect includes:<br />
        Log and Usage Data. Log and usage data is service-related, diagnostic,
        usage, and performance information our servers automatically collect
        when you access or use our Services and which we record in log files.
        Depending on how you interact with us, this log data may include your IP
        address, device information, browser type, and settings and information
        about your activity in the Services (such as the data/timestamps
        associated with your usage, pages, and files viewed, searches, and other
        actions you take such as which features you use), device event
        information (such as system activity, error reports (sometime called
        “crash dumps”), and hardware settings).<br />
        Device Data. We collect device data such as information about your
        computer, phone, tablet, or other device you use to access the Services.
        Depending on the device used, this device data may include information
        such as your IP address (or proxy server), device and application
        identification numbers, location, browser type, hardware model, Internet
        service provider and/or mobile carrier, operating system, and system
        configuration information.<br />
        Location Data. We collect location data such as information about your
        device’s location, which can be either precise or imprecise. How much
        information we collect depends on the type and settings of the device
        you use to access the Services. For example, we may use GPS and other
        technologies to collect geolocation data that tells us your current
        location. You can opt out of allowing us to collect this information
        either by refusing access to the information or by disabling your
        Location setting on your device. However, if you choose to opt out, you
        may be able to use certain aspects of the Services.<br />
        2. HOW DO WE PROCESS YOUR INFORMATION?<br />
        In Short: We process your information to provide, improve, and
        administer our Services, communicate with you, for security and fraud
        prevention, and to comply with law. We may also process your information
        for other purposes with your consent.<br />
        We process your personal information for a variety of reasons, depending
        on how you interact with our Services, including:<br />
        To facilitate account creation and authentication and otherwise manage
        user accounts. We may process your information so you can create and
        login to your account, as well as keep your account in working order.<br />
        To deliver and facilitate delivery of services to the user. We may
        process your information to provide you with the requested service.<br />
        To respond to user inquiries/offer support to users. We may process your
        information to respond to your inquiries and solve any potential issues
        you might have with the requested service.<br />
        To send administrative information to you. We may process your
        information to send you details about your products and services,
        changes to our terms and policies, and other similar information.<br />
        To enable user-to-user communications. We may process your information
        if you choose to use any of our offerings that allow for communication
        with another user.<br />
        To request feedback. We may process your information when necessary to
        request feedback and to contact you about your use of our Services.<br />
        To send your marketing and promotional communications. We may process
        the personal information you send to use for our marketing purposes, if
        this is in accordance with your marketing preferences. You can opt out
        of our marketing emails at any time.<br />
        To deliver targeted advertising to you. We may process your information
        to develop and display personalized content and advertising tailored to
        your interests, location, and more.<br />
        To protect our Services. We may process your information as part of our
        efforts to keep our Services safe and secure, including fraud monitoring
        and prevention.<br />
        To identify usage trends. We may process information about how you use
        our Services to better understand how they are being used so we can
        improve them.<br />
        To determine the effectiveness of our marketing and promotional
        campaigns. We may process your information to better understand how to
        provide marketing and promotional campaigns that are most relevant to
        you.<br />
        To save or protect an individual’s vital interest. We may process your
        information when necessary to save or protect an individual’s vital
        interest, such as to prevent harm.<br />
        3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?<br />
        In Short: We only process your personal information when we believe it
        is necessary and we have a valid legal reason (i.e. legal basis) to do
        so under applicable law, like with your consent, to comply with laws, to
        provide you with services to enter into or fulfill our contractual
        obligations, to protect your rights, or to fulfill our legitimate
        business interests.<br />
        If you are located in the EU or UK, this section applies to you.<br />
        The General Data Protection Regulation (GDPR) and UK GDPR require us to
        explain the valid legal bases we rely on in order to process your
        personal information. As such, we may rely on the following legal bases
        to process your personal information:<br />
        Consent. We may process your information if you have given us permission
        (i.e. consent) to use your personal information for a specific purpose.
        You can withdraw your consent at any time.<br />
        Performance of a Contract. We may process your personal information when
        we believe it is necessary to fulfill our contractual obligations to
        you, including providing our Services or at your request prior to
        entering into a contract with you.<br />
        Legitimate Interests. We may process your information when we believe it
        is reasonably necessary to achieve our legitimate business interests and
        those interests do not outweigh your interests and fundamental rights
        and freedoms. For example, we may process your personal information for
        some of the purposes described in order to:<br />
        Send users information about special offers and discounts on our
        products and services<br />
        Develop and display personalized and relevant advertising content for
        our users<br />
        Analyze how our services are used so we can improve them to engage and
        retain users<br />
        Support our marketing activities<br />
        Diagnose problems and/or prevent fraudulent activities<br />
        Understand how our users use our products and services so we can improve
        user experience<br />
        Legal Obligations. We may process your information where we believe it
        is necessary for compliance with our legal obligations, such as to
        cooperate with a law enforcement body or regulatory agency, exercise or
        defend our legal rights, or disclose your information as evidence in
        litigation in which we are involved.<br />
        Vital Interests. We may process your information where we believe it is
        necessary to protect your vital interests of a third party, such as
        situations involving potential threats to the safety of any person.<br />
        If you are located in Canada, this section applies to you.<br />
        We may process your information if you have given us specific permission
        (i.e. express consent) to use your personal information for a specific
        purpose, or in situations where your permission can be inferred (i.e.
        implied consent). You can withdraw your consent at any time.<br />
        In some exceptional cases, we may be legally permitted under applicable
        law to process your information without your consent, including for
        example:<br />
        If collection is clearly in the interests of an individual and consent
        cannot be obtained in a timely manner.<br />
        For investigations and fraud detection and prevention<br />
        For business transactions provided certain conditions are met<br />
        If it is contained in a witness statement and the collection is
        necessary to assess, process, or settle an insurance claim<br />
        For identifying injured, ill, or deceased persons and communicating with
        next of kin<br />
        If we have reasonable grounds to believe an individual has been, is, or
        may be victim of financial abuse<br />
        If it is reasonable to expect collection and use with consent would
        compromise the availability or the accuracy of the information and the
        collection is reasonable for purposes related to investigating a breach
        of an agreement or a contravention of the laws of Canada or a
        province<br />
        If disclosure is required to comply with a subpoena, warrant, court
        order, or rules of the court relating to the production of records<br />
        If it was produced by an individual in the course of their employment,
        business, or profession and the collection is consistent with the
        purposes for which the information was produced<br />
        If the collection is solely for journalistic, artistic, or literary
        purposes<br />
        If the information is publicly available and is specified by the
        regulations<br />
        4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?<br />
        In Short: We may share information in specific situations described in
        this section and/or with the following third parties.<br />
        We may need to share your personal information in the following
        situations:<br />
        Business Transfers. We may share or transfer your information in
        connection with, or during negotiations of, any merger, sale of company
        assets, financing, or acquisition of all or a portion of our business to
        another company.<br />
        Affiliates. We may share your information with our affiliates, in which
        case we will require those affiliates to honor this privacy policy.
        Affiliates include our parent company and any subsidiaries, joint
        venture partners, or other companies that we control or that are under
        common control with us.<br />
        Business Partners. We may share your information with our business
        partners to offer you certain products, services, or promotions.<br />
        5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES<br />
        In Short: We may use cookies and other tracking technologies to collect
        and store your information.<br />
        We may use cookies and similar tracking technologies (like web beacons
        and pixels) to access or store information. Specific information about
        how we use such technologies and how you can refuse certain cookies is
        set out in our Cookie Policy.<br />
        6. HOW LONG DO WE KEEP YOUR INFORMATION<br />
        In Short: We keep your information for as long as necessary to fulfill
        the purposes outlined in this privacy policy unless otherwise required
        by law.<br />
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy policy, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting, or other legal requirements). No purpose in this policy will
        require us to keep your personal information for longer than six (6)
        months past the termination of the user’s account.<br />
        When we have no ongoing legitimate business need to process your
        personal information, we will either delete or anonymize such
        information, or, if this is not possible (for example, because your
        personal information has been stored in backup archives), then we will
        securely store your personal information and isolate it from any further
        processing until deletion is possible.<br />
        7. HOW DO WE KEEP YOUR INFORMATION SAFE?<br />
        In Short: We aim to protect your personal information through a system
        of organizational and technical security measures.<br />
        We have implemented appropriate and reasonable technical and
        organizational security measures designed to protect the security of any
        personal information we process. However, despite our safeguards and
        efforts to secure your information, no electronic transmission over the
        Internet or information storage technology can be guaranteed to be 100%
        secure, so we cannot promise or guarantee that hackers, cybercriminals,
        or other unauthorized third parties will not be able to defeat our
        security and improperly collect, access, steal, or modify your
        information. Although we will do our best to protect your personal
        information, transmission of personal information to and from our
        Services is at your own risk. You should only access the Services within
        a secure environment.<br />
        8. WHAT ARE YOUR PRIVACY RIGHTS?<br />
        In Short: In some regions, such as the European Economic Area (EEA),
        United Kingdom (UK), and Canada, you have rights that allow you greater
        access to and control over your personal information, you may review,
        change, or terminate your account at any time.<br />
        In some regions (like the EEA, UK and Canada), you have certain rights
        under applicable data protection laws. These may include the right (i)
        to request access and obtain a copy of your personal information, (ii)
        to request rectification or erasure; (iii) to restrict the processing of
        your personal information; and (iv) if applicable, to data portability.
        In certain circumstances, you may also have the right to object to the
        processing of your personal information. You can make such a request by
        contacting us at user-privacy@ceebig.com<br />
        We will consider and act upon any request in accordance with applicable
        data protection laws.<br />
        If you are located in the EEA or UK and you believe we are unlawfully
        processing your personal information, you also have the right to
        complain to your local data protection supervisory authority. You can
        find their contact details here:
        <a
          href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html"
          >https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html</a
        >.<br />
        If you are located in Switzerland, the contact details for the data
        protection authorities are available here:
        <a href="https://www.edoeb.admin.ch/edobe/en/home.html"
          >https://www.edoeb.admin.ch/edobe/en/home.html</a
        ><br />
        Withdrawing your consent: if we are relying on your consent to process
        your personal information, which may be express and/or implied consent
        depending on the applicable law, you have the right to withdraw your
        consent at any time. You can withdraw your consent at any time by
        updating your preferences in the user account portal.<br />
        However, please note that this will not affect the lawfulness of the
        processing before its withdrawal, nor when applicable law allows, will
        it affect the processing of your personal information conducted in
        reliance on lawful processing grounds other than consent.<br />
        Opting out of marketing and promotional communications: You can
        unsubscribe from our marketing and promotional communications at any
        time by clicking on the unsubscribe link in the emails that we send, or
        by contacting us at user-privacy@ceebig.com. You will then be removed
        from the marketing lists. However, we may still communicate with you -
        for example, to send your service-related messages that are necessary
        for the administration and use of your account, to respond to service
        requests, or for other non-marketing purposes.<br />
        Account Information<br />
        If you would at any time like to review or change the information in
        your account or terminate your account, you can:<br />
        Login to your account settings and update your user account.<br />
        Upon your request to terminate your account, we will deactivate or
        delete your account and information from our active databases. However,
        we may retain some information in our files to prevent fraud,
        troubleshoot problems, assist with any investigations, enforce our legal
        terms and/or comply with applicable legal requirements.<br />
        Cookies and similar technologies: Most Web browsers are set to accept
        cookies by default. If you prefer, you can usually choose to set your
        browser to remove cookies and to reject cookies. If you choose to remove
        cookies or reject cookies, this could affect features or services of our
        Services.<br />
        If you have questions or comments about your privacy rights, you may
        email us at at user-privacy@ceebig.com<br />
        9. CONTROLS FOR DO-NOT-TRACK FEATURES<br />
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track (“DNT”) feature or setting you can
        activate to signal your privacy preference not to have data about your
        online browsing activities monitored and collected. At this stage no
        uniform technology standard for recognizing and implementing DNT signals
        has been finalized. As such, we do not currently respond to DNT browser
        signals or any other mechanism that automatically communicates your
        choice not to be tracked online. If a standard for online tracking is
        adopted that we must follow in the future, we will inform you about the
        practice in a revised version of this privacy policy.<br />
        10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?<br />
        In Short: Yes, if you are a resident of California, you are granted
        specific rights regarding access to your personal information.<br />
        California Civil Code Section 1798.83, also known as the the “Shine The
        Light” law, permits our users who are California residents to request
        and obtain from us, once a year and free of charge, information about
        categories of personal information (if any) we disclose to third parties
        for direct marketing purposes and the names and addresses of all third
        parties with which we shared personal information in the immediately
        preceding calendar year. If you are a California resident and would like
        to make such a request, please submit your request in writing to us
        using the contact information provided below.<br />
        If you are under 18 years of age, reside in California, and have a
        registered account with Services, you have the right to request removal
        of unwanted data that you publicly post on the Services. To request
        removal of such data, please contact us using the contact information
        provided below and include the email address associated with your
        account and a statement that you reside in California. We will make sure
        the data is not publicly displayed on the Services, but please be aware
        that the data may not be completely or comprehensively removed from all
        our systems (e.g., backups, etc).<br />
        CCPA Privacy Notice<br />
        The California Code of Regulations defines a “resident” as:<br />
        Every individual who is in the State of California for other than a
        temporary or transitory purpose and<br />
        Every individual who is domiciled in the State of California who is
        outside the State of California for a temporary or transitory purpose<br />
        All other individuals are defined as “non-residents”.<br />
        If this definition of “resident” applies to you, we must adhere to
        certain rights and obligations regarding your personal information.<br />
        What categories of personal information do we collect?<br />
        We have collected the following categories of personal information in
        the past twelve (12) months:<br />
        Category<br />
        Examples<br />
        Collected<br />
        A. Identifiers<br />
        Contact details, such as real name, alias, postal address, telephone or
        mobile contact number, unique personal identifier, online identifier,
        Internet Protocol address, email address, and account name<br />
        No<br />
        B. Personal Information categories listed in the California Customer
        Records statue<br />
        Name, contact information, education, employment, employment history,
        and financial information<br />
        No<br />
        C. Protected classification characteristics under California or federal
        law<br />
        Gender and date of birth<br />
        No<br />
        D. Commercial Information<br />
        Transaction information, purchase history, financial details, and
        payment information<br />
        No<br />
        E. Biometric Information<br />
        Fingerprints and voiceprints<br />
        No<br />
        F. Internet or other similar network activity<br />
        Browsing history, search history, online behavior, interest data, and
        interactions with our and other websites, applications, systems and
        advertisements<br />
        No<br />
        G. Geolocation data<br />
        Device location<br />
        No<br />
        H. Audio, electronic, visual, thermal, olfactory, or similar
        information<br />
        Images and audio, video or call recordings created in connection with
        our business activities<br />
        No<br />
        I. Professional or employment-related information<br />
        Business contact details in order to provide you our services at a
        business level or job title, work history, and professional
        qualifications if you apply for a job with us<br />
        No<br />
        J. Education Information<br />
        Student records and directory information<br />
        No<br />
        K. Inferences drawn from other personal information<br />
        Inferences drawn from any of the collected personal information listed
        above to create a profile or summary about, for example, an individual’s
        preferences and characteristics<br />
        No<br />
        We may also collect other personal information outside of these
        categories instances where you interact with us in person, online, or by
        phone or mail in the context of:<br />
        Receiving help through our customer support channels;<br />
        Participation in customer surveys or contests; and<br />
        Facilitation in the delivery of our Services and to respond to your
        inquiries.<br />
        How do we use and share your personal information?<br />
        More information about our data collection and sharing practices can be
        found in this privacy policy.<br />
        You may contact us by email at user-privacy@ceebig.com, or by referring
        to the contact details at the bottom of this document.<br />
        If you are using an authorized agent to exercise your right to opt out
        we may deny a request if the authorized agent does not submit proof that
        they have been validly authorized to act on your behalf.<br />
        Will your information be shared with anyone else?<br />
        We may disclose your personal information with our service providers
        pursuant to a written contract between us and each service provider.
        Each service provider is a for-profit entity that processes the
        information on our behalf.<br />
        We may use your personal information for our own business purposes, such
        as for undertaking internal research for technological development and
        demonstration. This is not considered to be “selling” of your personal
        information.<br />
        Ceebig Inc. has not disclosed or sold any personal information to third
        parties for a business or commercial purpose in the preceding twelve
        (12) months. Ceebig Inc. will not sell personal information in the
        future belonging to website visitors, users, and other customers.<br />
        Your rights with respect to your personal data<br />
        Right to request deletion of data - Request to delete<br />
        You can ask for the deletion of your personal information. If you ask us
        to delete your personal information, we will respect your request and
        delete your personal information, subject to certain exceptions provided
        by law, such as (but not limited to) the exercise by another consumer of
        his or her right to free speech, our compliance requirements resulting
        from a legal obligation, or any processing that may be required to
        protect against illegal activities.<br />
        Right to be informed - Request to know<br />
        Depending on the circumstances, you have a right to know:<br />
        Whether we collect and use your personal information;<br />
        The categories of personal information that we collect;<br />
        The purposes for which the collected personal information is used;<br />
        Whether we sell your personal information to third parties;<br />
        The categories of personal information that we sold or disclosed for a
        business purpose;<br />
        The categories of third parties to whom the personal information was
        sold or disclosed for a business purpose; and<br />
        The business or commercial purpose for collecting or selling personal
        information.<br />
        In accordance with applicable law, we are not obliged to provide or
        delete consumer information that is de-identified in response to a
        consumer request or to re-identify individual data to verify a consumer
        request.<br />
        Right to Non-Discrimination for the Exercise of a Consumer’s Privacy
        Rights<br />
        We will not discriminate against you if you exercise your privacy
        rights.<br />
        Verification process<br />
        Upon receiving your request, we will need to verify your identity to
        determine you are the same person about whom we have the information in
        our system. These verification efforts require us to ask you to provide
        information so that we can match it with information you have previously
        provided us. For instance, depending on the type of request you submit,
        we may ask you to provide certain information so that we can match the
        information you provide with the information we already have on file, or
        we may contact you through a communication method (i.e. phone or email)
        that you have previously provided to us. We may also use other
        verification methods as the circumstances dictate.<br />
        We will only use personal information provided in your request to verify
        your identity or authority to make the request. To the extent possible,
        we will avoid requesting additional information from you for the purpose
        of verification. However, if we cannot verify your identity from the
        information already maintained by us, we may request that you provide
        additional information for the purposes of verifying your identity and
        for security or fraud-prevention purposes. We will delete such
        additionally provided information as soon as we finish verifying you.<br />
        Other privacy rights<br />
        You may object to the processing of your personal information.<br />
        You may request correction of your personal data if it is incorrect or
        no longer relevant, or ask to restrict the processing of the
        information.<br />
        You can designate an authorized agent to make a request under the CCPA
        on your behalf. We may deny a request from an authorized agent that does
        not submit proof that they have been validly authorized to act on your
        behalf in accordance with the CCPA.<br />
        You may request to opt out from future selling of your personal
        information to third parties. Upon receiving an opt-out request, we will
        act upon the request as soon as feasibly possible, but no later than
        fifteen (15) days from the date of the requested submission.<br />
        To exercise these rights, you can contact us by email at
        user-privacy@ceebig.co, or by referring to the contact details at the
        bottom of this document. If you have a complaint about how we handle
        your data, we would like to hear from you.<br />
        11. HOW DO WE MAKE UPDATES TO THIS POLICY<br />
        In Short: Yes, we will update this policy as necessary to stay compliant
        with relevant laws.<br />
        We may update this privacy policy from time to time. The updated version
        will be indicated by an updated “Revised” date and the updated version
        will be effective as soon as it is accessible. If we make material
        changes to this privacy policy, we may notify you either by prominently
        posting a notice of such changes or by directly sending you a
        notification. We encourage you to review this privacy policy frequently
        to be informed of how we are protecting your information.<br />
        11. HOW DO WE MAKE UPDATES TO THIS POLICY<br />
        In Short: Yes, we will update this policy as necessary to stay compliant
        with relevant laws.<br />
        We may update this privacy policy from time to time. The updated version
        will be indicated by an updated “Revised” date and the updated version
        will be effective as soon as it is accessible. If we make material
        changes to this privacy policy, we may notify you either by prominently
        posting a notice of such changes or by directly sending you a
        notification. We encourage you to review this privacy policy frequently
        to be informed of how we are protecting your information.<br />
        12. HOW CAN YOU CONTACT US ABOUT THIS POLICY<br />
        If you have any questions or comments about this policy, you may email
        us at user-privacy@ceebig.com or by post to:<br />
        Ceebig Inc.<br />
        5820 N Church St<br />
        Suite D #262<br />
        Greensboro, NC 27455<br />
        United States<br />
        13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
        YOU?<br />
        Based on the applicable laws of your country, you may have the right to
        request access to the personal information we collect from you, change
        that information, or delete it. To request to review, update, or delete
        your personal information, please submit a request form.<br />
      </p>
    </a-layout-content>
  </a-layout>
</template>

<script>
import CommonHeader from "@/components/CommonHeader.vue";
export default {
  name: "PrivacyPolicyPage",
  components: {
    CommonHeader,
  },
};
</script>

<style scoped lang="scss">
.privacy-policy-page {
  text-align: left;
  color: var(--color1);
  h1 {
    margin-bottom: calc(100vw * (24 / 1440));
  }
  p {
    font-size: calc(100vw * (12 / 1440));
    line-height: calc(100vw * (25 / 1440));
    opacity: 0.6;
    letter-spacing: 0.2px;
  }
  a {
    text-decoration: underline;
  }
  a:link {
    color: var(--color1);
  }
}
</style>