import axios from 'axios';

// 创建axios实例
const http = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // 根据实际情况配置baseURL
    timeout: 5000, // 请求超时时间
    // headers: {
    //     "Content-type": "application/json"
    // }
});

// 请求拦截器
http.interceptors.request.use(
    config => {
        // 在发送请求之前做些什么
        // 可以在这里添加请求头等操作
        // config.headers['Authorization'] = 'Bearer ' + token;
        return config;
    },
    error => {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 响应拦截器
http.interceptors.response.use(
    response => {
        // 对响应数据做些什么
        // 可以在这里处理一些通用的响应逻辑
        return response.data;
    },
    error => {
        // 对响应错误做些什么
        return Promise.reject(error);
    }
);

// 封装四种请求类型
export const get = (url, params) => {
    return http.get(url, { params });
};

export const post = (url, data) => {
    return http.post(url, data);
};

export const put = (url, data) => {
    return http.put(url, data);
};

export const del = (url) => {
    return http.delete(url);
};
