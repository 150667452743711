import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/views/HomePage.vue'
import FAQPage from '@/views/FAQPage.vue'
import AboutUsPage from '@/views/AboutUsPage.vue'
import PrivacyPolicyPage from '@/views/PrivacyPolicyPage.vue'
import InvestPage from '@/views/InvestPage.vue'
import OurTeamPage from '@/views/OurTeamPage.vue'
import SubmitResultPage from '@/views/SubmitResultPage.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
      path: '/fqaPage',
      name: 'FAQPage',
      component: FAQPage
  },
  {
    path: '/aboutUsPage',
    name: 'AboutUsPage',
    component: AboutUsPage
  },
  {
    path: '/privacyPolicyPage',
    name: 'PrivacyPolicyPage',
    component: PrivacyPolicyPage
  },
  {
    path: '/investPage',
    name: 'InvestPage',
    component: InvestPage
  },
  {
    path: '/ourTeamPage',
    name: 'OurTeamPage',
    component: OurTeamPage
  },
  {
    path: '/submitResultPage',
    name: 'SubmitResultPage',
    component: SubmitResultPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    console.log('to',to, 'from',from, 'savedPosition',savedPosition)
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ el: to.hash,behavior: 'smooth' })
        }, 500)
      })
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 }
    }
  }
})

export default router