<template>
    <a-layout class="layout">
    <CommonHeader></CommonHeader>
    <a-layout-content class="bgc-grey our-team-page page-padding">
      <h1 class="title">{{ $t("our_team") }}</h1>
      <div class="member-list" v-for="(item, index) in ourTeamList" :key="index">
        <img :src="item.photo" alt="member photos">
        <div class="content-box">
            <p class="fw-300">{{ item.name }}</p>
            <p class="fw-400">{{ item.position }}</p>
            <a-divider />
            <p class="content">{{ item.description }}</p>
        </div>
      </div>
    </a-layout-content>
    <CommonFooter></CommonFooter>
  </a-layout>
</template>

<script>
import CommonHeader from "@/components/CommonHeader.vue";
import CommonFooter from "@/components/CommonFooter.vue";
import { useI18n } from "vue-i18n";
export default {
    name: 'OurTeamPage',
    components: {
        CommonHeader,
        CommonFooter,
    },
    setup() {
        const { t } = useI18n();
        const lang = 'en';
        const ourTeamList = [
            {
                photo: require("@/assets/img/team-members1.png"),
                name: 'Steve Rutherford',
                position: 'CEO',
                description: t("STEVE_DESCRIPTION", lang)
            },
            {
                photo: require("@/assets/img/team-members2.png"),
                name: 'Jacky Shi',
                position: 'COO',
                description: t("JACKY_DESCRIPTION", lang)
            },
            {
                photo: require("@/assets/img/team-members3.png"),
                name: 'Parrish Jacobi',
                position: 'CMO',
                description: t("PARRISH_DESCRIPTION", lang)
            },
            {
                photo: require("@/assets/img/team-members4.png"),
                name: 'Gregory Glover',
                position: 'CTO',
                description: t("GREGORY_DESCRIPTION", lang)
            }
        ]
        return {
            ourTeamList
        }
    }
}
</script>

<style scoped lang="scss">
    .our-team-page{
        text-align: left;
        h1 {
            line-height: calc(100vw * (60 / 1440)); 
            margin-bottom: calc(100vw * (110 / 1440));
        }
        .member-list {
            display: flex;
            margin-bottom: calc(100vw * (140 / 1440));
            img {
                width: calc(100vw * (325 / 1440));
                height: calc(100vw * (325 / 1440));
                margin-right: calc(100vw * (132 / 1440));
            }
            .content-box {
                width: calc(100vw * (680 / 1440));
                color: var(--color1);
                p {
                    margin-bottom: 0;
                }
                p:nth-child(1){
                    font-size: calc(100vw * (32 / 1440));
                    line-height: calc(100vw * (32 / 1440)); 
                }
                p:nth-child(2){
                    font-size: calc(100vw * (20 / 1440));
                    line-height: calc(100vw * (20 / 1440)); 
                    margin-top: calc(100vw * (8 / 1440));
                }
                .ant-divider {
                    margin: 0;
                    margin: calc(100vw * (28 / 1440)) 0;
                    border-block-start: 1px solid rgba(0, 0, 0, 1);
                }
                p:nth-child(4){
                    opacity: 0.6;
                }
            }
        }
    }
</style>