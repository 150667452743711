<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  text-align: center;
}
p,
h1,
div,
span, .button {
  font-family: "Open Sans";
  font-style: normal;
}
:root {
  --color1: #192030;
  --color2: rgba(151, 151, 151, 0.2);
  --color3: rgba(255, 255, 255, 0.8);
  --color4: #1a1919;
  --color5: #ff7e5b;
  --color6: #DD5A36;
  --color7: #FFFFFF;
}
.title {
  color: var(--color1);
  font-size: calc(100vw * (46 / 1440));
  font-weight: 300;
  line-height: calc(100vw * (54 / 1440));
}
.content {
  color: var(--color1);
  font-size: calc(100vw * (17 / 1440));
  font-weight: 400;
  line-height: calc(100vw * (30 / 1440));
  letter-spacing: 0.2px;
  opacity: 0.8;
}
.page-padding {
  padding: calc(100vw * (120 / 1440)) calc(100vw * (140 / 1440));
}
.bgc-grey {
  background: var(--color2);
}
.bgc-dark {
  background: var(--color4);
}

.bgc-white {
  background: var(--color7);
}

.btn-1 {
  color: #fff;
  background: var(--color6);
  border: 1.5px solid var(--color5);
  height: calc(100vw * (54 / 1440));
  font-size: calc(100vw * (16 / 1440));
  line-height: calc(100vw * (25 / 1440));
  letter-spacing: 0.2px;
}

.ant-btn.ant-btn-round {
  border-radius: calc(100vw * (32 / 1440));
}

.fw-300 {
  font-weight: 300;
}
.fw-350 {
  font-weight: 350;
}
.fw-400 {
  font-weight: 400;
}
.fw-600 {
  font-weight: 600;
}
.gradient-line {
  height: 2px;
  width: 100%;
  background: linear-gradient(to right, rgba(221,90,54,0.01), rgba(221,90,54,0.1) 20%, rgba(221,90,54,0.5) 70%, rgba(221,90,54,0.5));
}
</style>
