<template>
  <a-layout-header>
    <div class="logo hand-cursor" @click="goToHomePage">
      <img class="logo-img" src="../assets/img/logo.png" alt="" />
    </div>
    <a-breadcrumb separator=" ">
      <a-breadcrumb-item class="dropdown" v-if="batch1Visible">
        <a href="javascript:void(0)">{{ $t("products") }}</a>
        <template #overlay>
          <a-menu
            click="resources-menu"
            class="dropdown-product"
            :class="setColor()"
          >
            <a-menu-item
              @click="clickProductsMenu(proGroItem.title)"
              v-for="(proGroItem, proGroIndex) in productsMenu"
              :key="proGroIndex"
              >{{ proGroItem.title }}</a-menu-item
            >
          </a-menu>
        </template>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <router-link to="/aboutUsPage">{{ $t("about_Us") }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item  v-if="batch1Visible">
        <router-link :to="{ path: '/', hash: '#testimonials' }">{{ $t("testimonials") }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item class="dropdown"  v-if="batch1Visible">
        <a href="javascript:void(0)">Resources</a>
        <template #overlay>
          <a-menu
            click="resources-menu"
            class="dropdown-Resources"
            :class="setColor()"
          >
            <a-menu-item-group
              v-for="(resGroItem, resGroIndex) in resourcesMenu"
              :key="resGroIndex"
              :title="resGroItem.title"
            >
              <a-menu-item
                v-for="resItem in resGroItem.children"
                :key="resItem.key"
                >{{ resItem.title }}</a-menu-item
              >
            </a-menu-item-group>
          </a-menu>
        </template>
      </a-breadcrumb-item>

      <a-breadcrumb-item>
        <router-link to="/ourTeamPage">{{ $t("our_team") }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <router-link to="/investPage">{{ $t("invest") }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <a href="https://forms.office.com/r/QyHrk8cy4m" target="_blank" class="menu-layout btn-1 fw-400">
          {{$t('Preorder_Btn')}}
        </a>
      </a-breadcrumb-item>
    </a-breadcrumb>
  </a-layout-header>
</template>  
  
<script>
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
export default {
  name: "CommonHeader",
  setup(props, context) {
    const { t } = useI18n();
    const lang = "en";
    const router = useRouter();
    const resourcesMenu = [
      {
        title: t("siear_48", lang),
        children: [
          {
            title: t("user_guide", lang),
            key: "setting:1",
          },
          {
            title: t("specifications", lang),
            key: "setting:2",
          },
          {
            title: t("installation_guide", lang),
            key: "setting:3",
          },
        ],
      },
      {
        title: t("siear_600", lang),
        children: [
          {
            title: t("user_guide", lang),
            key: "setting:4",
          },
          {
            title: t("specifications", lang),
            key: "setting:5",
          },
          {
            title: t("installation_guide", lang),
            key: "setting:6",
          },
        ],
      },
    ];
    const productsMenu = [
      {
        title: t("siear_48", lang),
        children: [],
      },
      {
        title: t("siear_600", lang),
        children: [],
      },
    ];
    const goToHomePage = () => {
      router.push({
        path: "/",
        query: {
          position: "fromLogo",
        },
      });
    };
    const currentRoute = router.currentRoute.value.path;
    const setColor = () => {
      return currentRoute == "/" ? "white" : "black";
    };
    const clickProductsMenu = (type) => {
      console.log(currentRoute);
      if (currentRoute != "/") {
        router.push({
          path: "/",
          query: {
            productType: type,
          },
        });
      }
      context.emit("product-menu", type);
    };
    const batch1Visible=false;
    return {
      resourcesMenu,
      productsMenu,
      goToHomePage,
      setColor,
      clickProductsMenu,
      batch1Visible,
    };
  },
};
</script>

<style scoped>
.ant-layout-header {
  display: flex;
  justify-content: space-between;
  background-color: var(--color4);
  height: calc(100vw * (100 / 1440));
  padding: 0 calc(100vw * (140 / 1440));
}
.ant-layout-header .logo {
  text-align: left;
}
.ant-layout-header .logo-img {
  height: calc(100vw * (65 / 1440));
  margin-top: calc(100vw * (25 / 1440));
  margin-bottom: calc(100vw * (10 / 1440));
  display: block;
}
.ant-layout-header .ant-breadcrumb {
  background: var(--color4);
  color: #fff;
  font-size: calc(100vw * (16 / 1440));
  font-weight: 300;
  line-height: calc(100vw * (23 / 1440));
  letter-spacing: 0.2px;
}
.ant-layout-header .ant-breadcrumb /deep/ ol,.menu-layout {
  display: flex;
  height: 100%;
  align-items: flex-end;
  padding-bottom: calc(100vw * (25 / 1440));
  justify-content: flex-start;
}
.ant-layout-header .ant-breadcrumb /deep/ li,.menu-layout {
  width: calc(100vw * (135 / 1440));
  text-align: left;
}

.ant-layout-header .menu-layout {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
    width: calc(100vw * (120 / 1440)) !important;
    padding:13px 10px;
    margin-left: calc(100vw * (-40 / 1440));
    border-radius:calc(100vw * (32 / 1440));
}
.menu-layout:hover {
    color: #4096ff;
    background-color: var(--color6);
    border-color: #4096ff;
    transition: color 0.3s ease, background-color 0.3s ease;
}
.ant-layout-header .ant-breadcrumb /deep/ :last-child {
  width: auto;
}
.ant-layout-header /deep/ .ant-breadcrumb-separator {
  margin-inline: calc(100vw * (25 / 1440));
}
.ant-breadcrumb /deep/.ant-breadcrumb-overlay-link {
  height: calc(100vw * (22 / 1440));
}
.ant-layout-header .ant-breadcrumb a {
  color: #fff;
  height: calc(100vw * (22 / 1440));
}
.ant-dropdown .ant-dropdown-menu {
  background-color: rgba(0, 0, 0, 0);
}
.dropdown /deep/ .anticon-down {
  color: #fff;
  margin-left: calc(100vw * (12 / 1440));
  vertical-align: middle;
  font-size: calc(100vw * (12 / 1440));
}

.ant-dropdown-menu {
  border: 1px solid #000;
  border-radius: 0%;
  display: flex;
}
.ant-dropdown-menu /deep/ .ant-dropdown-menu-item-group-list {
  margin: 0;
}
.dropdown-product,
.dropdown-Resources {
  padding: calc(100vw * (8 / 1440)) calc(100vw * (12 / 1440));
  margin-top: calc(100vw * (25 / 1440));
}
.dropdown-product /deep/li:nth-child(1),
.dropdown-Resources /deep/ .ant-dropdown-menu-item-group:nth-child(1) {
  border-right: 1px solid #000;
  border-radius: 0;
  padding-right: calc(100vw * (12 / 1440));
}
.dropdown-product /deep/li:nth-child(2),
.dropdown-Resources /deep/ .ant-dropdown-menu-item-group:nth-child(2) {
  padding-left: calc(100vw * (12 / 1440));
}
.white /deep/ .ant-dropdown-menu-title-content,
.white /deep/ .ant-dropdown-menu-item-group-title {
  color: #fff;
}
.black /deep/ .ant-dropdown-menu-title-content,
.black /deep/ .ant-dropdown-menu-item-group-title {
  color: #000;
}
.dropdown-product /deep/ .ant-dropdown-menu-title-content,
.dropdown-Resources /deep/ .ant-dropdown-menu-item-group-title {
  font-size: calc(100vw * (16 / 1440));
  font-weight: 800;
  line-height: calc(100vw * (23 / 1440));
}
.dropdown-Resources /deep/ .ant-dropdown-menu-title-content {
  font-size: calc(100vw * (14 / 1440));
  font-weight: 300;
  line-height: calc(100vw * (23 / 1440));
  opacity: 0.6;
}
.ant-dropdown-menu /deep/.ant-dropdown-menu-item-group:nth-child(1) {
  border-right: 1px solid #000;
}
.hand-cursor {
  cursor: pointer;
}

@media (max-width: 600px) {
  .ant-layout-header {
    /* height: 50px; */
    /* padding: 0 12px; */
  }
  .ant-layout-header .logo-img {
    /* height: 50px; */
    /* margin: 0; */
    vertical-align: unset;
  }
  .ant-layout-header /deep/ .ant-breadcrumb-separator {
    margin-inline: 5px;
  }
  .ant-layout-header .ant-breadcrumb /deep/ ol {
    padding: 0;
    align-items: center;
    padding-top: 12px;
  }
}
</style>